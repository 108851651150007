import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_TabsDex = _resolveComponent("TabsDex")!
  const _component_TabsStudio = _resolveComponent("TabsStudio")!
  const _component_IonSplitPane = _resolveComponent("IonSplitPane")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, null, {
    default: _withCtx(() => [
      _createVNode(_component_IonSplitPane, {
        "content-id": "main-content",
        when: "(min-width: 8000px)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, {
            id: "main-content",
            class: "main-content"
          }),
          _createVNode(_component_TabsDex),
          _createVNode(_component_TabsStudio)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}