/* eslint-disable */

import { createApp } from 'vue'
/* import { createStore } from 'vuex' */
import App from './App.vue'

import mitt from 'mitt';
const emitter = mitt();

import { BackgroundMode } from '@ionic-native/background-mode';
import { Insomnia } from '@ionic-native/insomnia';

import { KeepAwake } from '@capacitor-community/keep-awake';

import { Capacitor } from '@capacitor/core';

import { isAuthenticated } from './router';

const keepAwake = async () => {
  if (Capacitor.getPlatform() != 'web') {
    await KeepAwake.keepAwake();
  }
};

const allowSleep = async () => {
  await KeepAwake.allowSleep();
};

import router from './router';
/* import { storeData } from './views/player/store.js' */
import { IonicVue } from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/admin_variables.css';


// const store = createStore(storeData);

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  // .use(VueAxios)

// BackgroundMode.enable();

router.isReady().then(() => {
  app.config.globalProperties.emitter = emitter;
  app.mount('#app');
  if (Capacitor.getPlatform() != 'ios'){
    BackgroundMode.enable();
  }
  keepAwake();
});

// declare global {
//   interface Window { 
//     hub: any; 
//     xhr: any; 
//     timeouts: any;
//     audio: any;
//     video: any;
//     vm: any;
//     cleanHash: any;
//     oaudio: any; }
// }


// // The global object for comunications between components
// window.hub = createApp(App);

// // XHR Library for AJAX
// window.xhr = axios;

// // Timeouts object
// window.timeouts = {};

// // Core setup steps
// // Setting the hash in case it's blank
// if(window.location.hash == '' && localStorage.lastlink) {
//   window.location.hash = localStorage.lastlink;
// }

// // Global helpers
// window.cleanHash = () => {
//   const rawHash = window.location.hash.match(/[a-z0-9]/gi);
//   if (!rawHash) return "";
//   return rawHash.join('');
// };

// // Global objects that require no reactivity
// window.audio = new Audio();
// window.video = document.getElementById('video');
// window.oaudio = new Audio();
// window.timeouts = {};

// // The main instance that launches the app
// window.vm = createApp(App);