
/* eslint-disable */
import { alertController, IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import { Storage } from '@capacitor/storage';
import { menuController } from "@ionic/core";
import TabsDex from  './views/TabsDex.vue';
import TabsStudio from  './views/TabsStudio.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet, 
    IonSplitPane, 
    TabsDex,
    TabsStudio,
  },
  setup() {
    const selectedIndex = ref(0);
    const appPages = [
      {
        title: 'Inicio',
        url: '/login/loginPage',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
    ];
    const labels = ['Spot para radio', 'Audio para video', 'Perifoneo', 'Hold telefónico', 'Logotipo auditivo', 'Jingle'];
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages, 
      labels,
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  },
  methods: {
    logout(){
      Storage.clear();
      menuController.close('menustudio');
      this.$router.replace("/login/loginPage");
      // console.log("clientData removed");
    },
    async presentAlertConfirm(headerAlert,messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
      },
  }
});
