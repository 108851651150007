<template>
  <ion-menu menuId="menudex" side="end" menu-id="menudex" class="menudex" content-id="main-content" type="overlay"
    v-bind:disabled="($route.name == 'LoginPage' || $route.name == 'LoginUser') ? true : false" id="menudex">
    <ion-content style="background-color:#1e1e1e;">
      <div class="back_menu_o"></div>
      
              <ion-list id="labels-list bottom-content">
                <hr class=" ">
      
                <ion-img v-if="!sonara" src="assets/ICONOS/Servicios/vybroo-studio.svg"
                  style="transform: scale(0.7);"></ion-img>
                <ion-text v-if="!sonara" class="txtSubtituloStudio">
                  <h5 style=" text-align: center;">Complementa <br> tu canal musical</h5>
                </ion-text>
                <hr class="white_hr">
                <!--<hr v-if="!sonara" class="white_hr hr_no_margin">-->
                <ion-item v-if="!sonara" detail="false" :class="$route.name == 'spotRadio' ? '' : 'unselectedTab'"
                  lines="full" button @click="menuNavigation('/tabs/spotRadio')">
                  <ion-icon auto-hide="true" slot="start" src="assets/ICONOS/Servicios/spot_vybroo_studio.svg"></ion-icon>
                  <ion-label>Spot para radio</ion-label>
                  <ion-icon slot="end" src="assets/chevron-forward-outline.svg"></ion-icon>
                </ion-item>
                <!--<hr v-if="!sonara" class="white_hr hr_no_margin">-->
                <ion-item v-if="!sonara" detail="false" :class="$route.name == 'audioVideo' ? '' : 'unselectedTab'"
                  lines="full" button @click="menuNavigation('/tabs/audioVideo')">
                  <ion-icon slot="start" src="assets/ICONOS/Servicios/audio_video_vybroo_studio.svg"></ion-icon>
                  <ion-label>Audio para video</ion-label>
                  <ion-icon slot="end" src="assets/chevron-forward-outline.svg"></ion-icon>
                </ion-item>
                <!--<hr v-if="!sonara" class="white_hr hr_no_margin">-->
                <ion-item v-if="!sonara" detail="false" :class="$route.name == 'perifoneo' ? '' : 'unselectedTab'"
                  lines="full" button @click="menuNavigation('/tabs/perifoneo')">
                  <ion-icon slot="start" src="assets/ICONOS/Servicios/perifoneo_vybroo_studio.svg"></ion-icon>
                  <ion-label>Perifoneo</ion-label>
                  <ion-icon slot="end" src="assets/chevron-forward-outline.svg"></ion-icon>
                </ion-item>
                <!--<hr v-if="!sonara" class="white_hr hr_no_margin">-->
                <ion-item v-if="!sonara" detail="false" :class="$route.name == 'holdTelefonico' ? '' : 'unselectedTab'"
                  lines="full" button @click="menuNavigation('/tabs/holdTelefonico')">
                  <ion-icon slot="start" src="assets/ICONOS/Servicios/hold_telefonico_vybroo_studio.svg"></ion-icon>
                  <ion-label>Hold telefónico</ion-label>
                  <ion-icon slot="end" src="assets/chevron-forward-outline.svg"></ion-icon>
                </ion-item>
                <!--<hr v-if="!sonara" class="white_hr hr_no_margin">-->
                <ion-item v-if="!sonara" detail="false" :class="$route.name == 'logotipoAuditivo' ? '' : 'unselectedTab'"
                  lines="full" button @click="menuNavigation('/tabs/logotipoAuditivo')">
                  <ion-icon slot="start" src="assets/ICONOS/Servicios/logotipo_vybroo_studio.svg"></ion-icon>
                  <ion-label>Logotipo auditivo</ion-label>
                  <ion-icon slot="end" src="assets/chevron-forward-outline.svg"></ion-icon>
                </ion-item>
                <!--<hr v-if="!sonara" class="white_hr hr_no_margin">-->
                <ion-item v-if="!sonara" detail="false" :class="$route.name == 'jingle' ? '' : 'unselectedTab'" lines="full"
                  button @click="menuNavigation('/tabs/jingle')">
                  <ion-icon slot="start" src="assets/ICONOS/Servicios/jingle_vybroo_studio.svg"></ion-icon>
                  <ion-label>Jingle</ion-label>
                  <ion-icon slot="end" src="assets/chevron-forward-outline.svg"></ion-icon>
                </ion-item>
                 <hr v-if="!sonara" class="white_hr hr_no_margin"> 
                <ion-item>
                  <div class="foot_menu_vs">
                    <h4><strong> ¿Quieres conocer más sobre nuestros servicios?</strong></h4>
                    <span v-if="!sonara"><small>Visita nuestro sitio web</small></span>
      
                    <ion-button v-if="!sonara" fill="solid" color="dark" style=" text-transform: none;font-weight: 600;"
                      href="http://www.vybroo.com" target="_blank">www.vybroo.com</ion-button>
                    <br v-if="!sonara">
                    <br v-if="!sonara">
                    <span v-if="!sonara" class="d-block"><small>-&nbsp; ó &nbsp;-</small></span>
                    <a v-if="sonara" class="center_icon" target="_blank"
                      href="https://api.whatsapp.com/send?phone=5216671175050&text=Hola!%20Me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20los%20servicios%20de%20Sonara.">
                      <button class="wp_button_vs">
                        <ion-icon class="wp_img_vs" slot="start" src="assets/ICONOS/whatsapp.svg"></ion-icon>
                        <span class="txtContactoWhats"> <strong style="color: #fff;" class="txtContactoWhats">Contáctanos por
                            WhatsApp</strong></span>
                      </button>
                    </a>
                    <a v-else class="center_icon" target="_blank"
                      href="https://api.whatsapp.com/send?phone=5215568725539&text=Hola!%20Me%20gustar%C3%ADa%20tener%20m%C3%A1s%20informaci%C3%B3n%20acerca%20de%20los%20servicios%20de%20Vybroo.">
                      <button class="wp_button_vs">
                        <ion-icon class="wp_img_vs" slot="start" src="assets/ICONOS/whatsapp.svg"></ion-icon>
                        <span class="txtContactoWhats"> <strong style="color: #fff;" class="txtContactoWhats">Contáctanos por
                            WhatsApp</strong></span>
                      </button>
                    </a>
      
                  </div>
                </ion-item> 
      
              </ion-list>
    </ion-content>
  </ion-menu>
</template>
<script>
/* eslint-disable */
import {
  IonMenu, IonContent, IonItem, IonList, IonLabel, menuController, IonGrid, IonCol, IonRow, IonIcon, alertController
} from "@ionic/vue";
import axios from 'axios';
import { Storage } from '@capacitor/storage';
import { arrowDownCircle } from 'ionicons/icons';


export default ({
  components: {
    IonMenu, IonContent, IonItem, IonList, IonLabel, IonGrid, IonCol, IonRow, IonIcon
  },
  data() {
    return {
      sonara: false,


    }
  },
  methods: {
    openMenu() {
      menuController.open("menudex");
    },
    menuNavigation(url) {
      menuController.close("menudex");
      this.$router.push(url);
    },
 
     
  },
  mounted: function () {
  
        this.emitter.on("sonara", (data) => {
          this.sonara = data;
          console.log("Emitido sonara: " + data);
        });
  },
});
</script>