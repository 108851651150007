import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import Tabs from '../views/Tabs.vue'
import { Storage } from '@capacitor/storage'
import axios from 'axios'

export async function isAuthenticated() {
  const authToken = await Storage.get({ key: 'authToken' })
  if (!authToken.value) return !!authToken.value
}

// Función de autenticación
async function authenticate(to, from, next) {
  // Si la ruta no requiere autenticación, permitir el acceso sin verificar la autenticación del usuario
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    return next()
  }

  // De lo contrario, verificar la autenticación del usuario como se describe en el ejemplo anterior

  // Intentar obtener el token de acceso almacenado en el almacenamiento local
  const accessToken = await Storage.get({ key: 'authToken' })
  console.log('AccessToken.value: ' + accessToken.value)
  // Si no hay un token de acceso almacenado, redirigir al usuario a la página de inicio de sesión
  if (!accessToken.value) {
    console.log('No hay access token, redirigiendo a loginAdmin')
    return next({ name: 'LoginAdmin' })
  }
  // const accessTokenValue = JSON.parse(accessToken.value || "");
  const accessTokenValue = accessToken
  console.log('AccessToken:')
  console.log(accessTokenValue)

  // Si hay un token de acceso almacenado, verificar su validez a través del servicio de autenticación
  try {
    // const authenticated = await axios.post('https://panel.vybroo.net/user', {token: accessToken});
    const formData = new FormData()
    formData.append('token', JSON.stringify(accessToken.value))
    const authenticated = await axios({
      method: 'POST',
      url: '/api/user_auth',
      data: { token: accessToken.value },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    if (!authenticated) {
      // Si el token no es válido, eliminarlo del almacenamiento local y redirigir al usuario a la página de inicio de sesión
      Storage.remove({ key: 'authToken' })
      return next({ name: 'LoginAdmin' })
    }
    console.log(authenticated)
  } catch (error) {
    // Si hay un error al verificar el token, eliminarlo del almacenamiento local y redirigir al usuario a la página de inicio de sesión
    Storage.remove({ key: 'authToken' })
    return next({ name: 'LoginAdmin' })
  }

  // Si el token es válido, permitir que el usuario acceda a la ruta protegida
  return next()
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login/LoginPage/',
  },
  {
    path: '/:channellink',
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/login/directLogin/' + to.params.channellink }
    },
  },
  {
    path: '/login/directLogin/:channellink',
    name: 'directLogin',
    component: () => import('../views/login/directLogin.vue'),
  },
  {
    path: '/login/LoginPage',
    name: 'LoginPage',
    component: () => import('../views/login/LoginPage.vue'),
  },
  {
    path: '/login/LoginUser',
    name: 'LoginUser',
    component: () => import('../views/login/loginUser.vue'),
  },
  {
    path: '/folder/:id',
    component: () => import('../views/Folder.vue'),
  },
  {
    path: '/player/:channel_name',
    component: () => import('../views/Player.vue'),
  },

  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/tabReproductor',
      },
      {
        path: 'tabReproductor',
        name: 'tabReproductor',
        component: () => import('@/views/tabReproductor.vue'),
      },
      {
        path: 'tabCategorias',
        name: 'tabCategorias',
        component: () => import('@/views/tabCategorias.vue'),
      },
      {
        path: 'tabVariantes',
        name: 'tabVariantes',
        component: () => import('@/views/tabVariantes.vue'),
      },
      {
        path: 'tabAudios',
        name: 'tabAudios',
        component: () => import('@/views/tabAudios.vue'),
      },
      {
        path: 'tabSucursales',
        name: 'tabSucursales',
        component: () => import('@/views/tabSucursales.vue'),
      },
      {
        path: 'tabLinks',
        name: 'tabLinks',
        component: () => import('@/views/tabLinks.vue'),
      },
      {
        path: 'audioVideo',
        name: 'audioVideo',
        component: () => import('@/views/vybrooStudio/audioVideo.vue'),
      },
      {
        path: 'holdTelefonico',
        name: 'holdTelefonico',
        component: () => import('@/views/vybrooStudio/holdTelefonico.vue'),
      },
      {
        path: 'jingle',
        name: 'jingle',
        component: () => import('@/views/vybrooStudio/jingle.vue'),
      },
      {
        path: 'logotipoAuditivo',
        name: 'logotipoAuditivo',
        component: () => import('@/views/vybrooStudio/logotipoAuditivo.vue'),
      },
      {
        path: 'perifoneo',
        name: 'perifoneo',
        component: () => import('@/views/vybrooStudio/perifoneo.vue'),
      },
      {
        path: 'spotRadio',
        name: 'spotRadio',
        component: () => import('@/views/vybrooStudio/spotRadio.vue'),
      },
      {
        path: '/admin/LoginAdmin',
        name: 'LoginAdmin',
        component: () => import('@/views/admin/loginAdmin.vue'),
      },
      {
        path: '/admin/cabinaAdmin',
        name: 'CabinaAdmin',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/cabinaAdmin.vue'),
      },
      {
        path: '/admin/dashboard',
        name: 'Dashboard',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/dashboard.vue'),
      },
      {
        path: '/admin/conexiones',
        name: 'Conexiones',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/conexiones.vue'),
      },
      {
        path: '/admin/logs',
        name: 'Logs',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/logs.vue'),
      },
      {
        path: '/admin/spots',
        name: 'Spots',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/spots.vue'),
      },
      {
        path: '/admin/sucursales',
        name: 'Sucursales',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/sucursales.vue'),
      },
      {
        path: '/admin/configuraciones',
        name: 'Configuraciones',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/configuraciones.vue'),
      },
      {
        path: '/admin/musica',
        name: 'Musica',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/musica.vue'),
      },
      {
        path: '/admin/sucursalesPorHora',
        name: 'SucursalesPorHora',
        meta: { requiresAuth: true },
        component: () => import('@/views/admin/sucursalesPorHora.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// Añadir la función de autenticación a las rutas protegidas
router.beforeEach(authenticate)

export default router
