<template>
  <ion-header>
    <ion-content :class="videoOn ? 'videoOn' : 'fullscreeen'" class="contentt" >
        <div class="m_button" @click="closeModal()" :style="{'background-color':'#FFFFFF'}" v-if="!clientImageSrc" >
            <ion-img class="m_image" src="assets/reproductor/logo_default.jpg" ></ion-img>
        </div>
        <div class="m_button" @click="closeModal()" :style="{'background-color':clientColor}" v-else>
            <ion-img class="m_image" :src="clientImageSrc" :alt="clientName + '_logo'"></ion-img>
        </div>
        <!-- botón de play centrado para cuando el navegador bloquee el autoplay -->
        <div class="play_video_btn" @click="playVideo()"  :style="autoplay_blocked? 'display:flex;':'display:none;'">
            <ion-img class="m_image" src="assets/ICONOS/play.svg" ></ion-img>
        </div>
        
        <video autoplay ref="video_f" class="video_p" poster="assets/black-poster.png">
            <source :src="videoo" type="video/mp4">
        </video>
    </ion-content>
  </ion-header>
</template>

<script>
/* eslint-disable */
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';
import { Storage } from '@capacitor/storage';
import axios from 'axios';

export default defineComponent({
  name: 'RepModal',
  props: {
    title: { type: String },
    video: { type: String },
  },
  components: { IonContent, IonHeader},
  data () {
    return {
      clientImageSrc: "",
      clientColor: "",
      videoo: "",
      videoOn: false,
      fullScreenOn: false,
      status: "ok",
      autoplay_blocked: false,
      mountedLoaded: false,
      emittworker: null,
    }
  },
  methods: {
        closeModal() {
          this.fullScreenOn = false;
          this.emitter.emit("fullScreenOn", false);
          modalController.dismiss({dismissed: true});
        },
        async loadClient () {
            const ret = await Storage.get({ key: "clientData" });
            if(ret.value) this.clientData = JSON.parse(ret.value);
            else console.log("MODAL: no se pudo consultar clientData del Storage");
            this.clientImageSrc = "https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/" + this.clientData.image;
            this.clientColor = "#" + this.clientData.color; 
        },
        setSource(src){
          console.log("Emmiter-setSource: " + src);
            try {
              this.videoOn = true;
              this.videoo = src; 
              const video_f = this.$refs.video_f;
              
              //prevenir que el modal videoOn se pueda cerrar al presionar escape
              video_f.addEventListener('keydown', function(e) {
                if (e.keyCode == 27) {
                  e.preventDefault();
                }
              });


              video_f.load();
            } catch (error) {
              console.log("Error al cargar video: " + error);
            }
            
        },
        playVideo(){
          const video_f = this.$refs.video_f;
          video_f.play();
        }
  },
  mounted: function() {
    try {
      if (this.mountedLoaded) return;
      this.mountedLoaded = true;
      const tt = this;
      this.loadClient();
      this.videoo = this.$props.video;
      if (this.videoo!="") {
          console.log("rep-Videoprops: " + this.$props.video);
            this.videoOn = true; 
      }else{
        this.videoOn = false;
        this.fullScreenOn = true;
      }
        const video_f = this.$refs.video_f;
  
          video_f.addEventListener('timeupdate', function() {
            const timeLeft = video_f.duration - video_f.currentTime;
            if (timeLeft >= 1 && timeLeft <= 2) {
              console.log('El video está por terminar:'+timeLeft+' duration:'+video_f.duration+' currentTime:'+video_f.currentTime+'==');
            }
  
            tt.autoplay_blocked?tt.autoplay_blocked = false:"";
          });
          // AL TERMINAR EL VIDEO
          video_f.addEventListener('ended', function() {
            console.log('El video terminó');
            video_f.pause();
            tt.emitter.emit("videoModalEnd", tt.status);
          });
  
          video_f.addEventListener('play', function() {
            if (video_f.paused) {
              if (videoOn) {
                tt.autoplay_blocked = true;
              }
            } else {
              tt.autoplay_blocked = false;
            }
          });
          
          video_f.addEventListener('error', function(event) {
            switch (event.target.error.code) {
              case event.target.error.MEDIA_ERR_ABORTED:
                console.log('La carga del video fue abortada.');
                break;
              case event.target.error.MEDIA_ERR_NETWORK:
                console.log('Se produjo un error de red al cargar el video.');
                break;
              case event.target.error.MEDIA_ERR_DECODE:
                console.log('Se produjo un error al decodificar el video.');
                break;
              case event.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                console.log('El formato del video no es compatible.');
                break;
              default:
                console.log('Se produjo un error al cargar el video.');
                break;
            }
          });
  
          //si se pausa el video mostrar el botón de play
          //console.log("listener de pause")
          video_f.addEventListener('pause', function() {
            console.log('Video paused');
            
          });
  
          if (video_f.autoplay) {
            video_f.play().catch(error => {
              //console.log("ERROR AUTPLAY:: El usuario necesita dar clic: "+error+"");
              tt.autoplay_blocked = true;
            });
          }

      this.emitter.on("fullScreenOn", (status) => {
        //console.log("fullScreenOn: " + status);
        this.fullScreenOn = status;
      });
  
      // AL CARGAR EL VIDEO
      this.emitter.on("rep-VideoSource", (playerAudioSource) => {
        tt.setSource(playerAudioSource);
        //.log("rep-VideoSource: " + playerAudioSource);
      });
      // AL CARGAR EL VIDEO
      this.emitter.off("rep-type");
      this.emitter.on("rep-type", (type) => {
        if (type != "video") {
          video_f.pause();
          //prevenir que se dupliquen los listeners de los emits
          // this.emitter.off("rep-VideoSource");
          //console.log("fullScreenOn: " + this.fullScreenOn);
          if (this.fullScreenOn == false && this.videoOn == true) {
            modalController.dismiss({dismissed: true});
          }
          this.videoOn = false;
        }
        console.log("emit type: " + type);
      });

      

    } catch (error) {
      console.log("Error en mounted de ModalRepFullView: " + error);
    }
    
  },
  setup() {
      return {close,};
  },
});
</script>

<style scoped>

.fullscreeen .play_video_btn{
    display: none !important;
}
.contentt{
    height: calc(100vh);
}
.m_button{
    width: 100%;
    height: 100vh;
    border: solid #ccc 0px;
    vertical-align: middle;
    padding: 0px;
    margin: 0px;
    display: none;
}
.fullscreeen .m_button{
    display: grid;
}
.m_image{
    max-width: 80%;
    vertical-align: middle;
    max-height: 80%;
    margin: auto;
}
.sc-ion-modal-md-h,
.modal-wrapper{
    --width: 100%;
    --height: 100vh;
    width: 100%;
    height: 100vh;
}
.video_p{
    width: 100%;
    height: 100vh;
    background-color: #000;
    display: none;
}
.videoOn .video_p{
    display: block;
}

.play_video_btn {
    width: 70px;
    height: 70px;
    border: solid #ccc 0px;
    vertical-align: middle;
    z-index: 2;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    transition: all 0.3s ease;
}




.play_video_btn:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    /* border-radius: 50%; */
    background: rgba(0, 0, 0, 0.5);
    z-index: -2;
    /* border: 2px solid #ccc; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
}


.play_video_btn .m_image::before{
  content: "";
    position: absolute;
    width: 180%;
    height: 180%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.678);
    z-index: -1;
    border: 2px solid #ccc;
    top: 50%;
    left: calc(50% - 3px);
    transform: translate(-54%, -50%) scale(1);
    animation-delay: 7s;
    animation: heartBeat 7s infinite;
}
 
.play_video_btn .m_image:hover {
    filter: invert(1);
}
.play_video_btn:hover:after {
    
}
.play_video_btn .m_image {
  animation-delay: 7s;
  animation: heartBeat 7s infinite;
    left: 50%;
    top: 50%;
    position: absolute;
    min-width: 35px;
    min-height: 35px;
}

@keyframes heartBeat {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }
    25% {
        transform: translate(-50%, -50%) scale(1.05);
    }
    50% {
        transform: translate(-50%, -50%) scale(1);
    }
    75% {
        transform: translate(-50%, -50%) scale(1.05);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}


</style>