<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="tabBarBottom">
        <ion-tab-button tab="tabReproductor" v-on:click="$router.push('/tabs/tabReproductor')" id="tabReproductor"
          class="tabButton">
          <ion-icon v-show="$route.name !== 'tabReproductor'" src="assets/ICONOS/reproductor-gris.svg" />
          <ion-icon v-show="$route.name == 'tabReproductor'" src="assets/ICONOS/reproductor-blanco.svg" />
          <ion-label :class="$route.name == 'tabReproductor' ? 'selectedTab' : ''">Reproductor</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tabCategorias" v-on:click="$router.push('/tabs/tabCategorias'); getddata();"
          id="tabCategorias" class="tabButton">
          <ion-icon v-show="$route.name !== 'tabCategorias'" src="assets/ICONOS/categoria-musical-gris.svg" />
          <ion-icon v-show="$route.name == 'tabCategorias'" src="assets/ICONOS/categoria-musical-blanco.svg" />
          <ion-label :class="$route.name == 'tabCategorias' ? 'selectedTab' : ''">Categorías</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tabVariantes" v-on:click="$router.push('/tabs/tabVariantes'); getddata();"
          id="tabVariantes" class="tabButton">
          <ion-icon v-show="$route.name !== 'tabVariantes'" src="assets/ICONOS/variante-gris.svg" />
          <ion-icon v-show="$route.name == 'tabVariantes'" src="assets/ICONOS/variante-blanco.svg" />
          <ion-label :class="$route.name == 'tabVariantes' ? 'selectedTab' : ''">Contenidos</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tabAudios" v-on:click="$router.push('/tabs/tabAudios'); getddata();" id="tabAudios"
          class="tabButton">
          <ion-icon v-show="$route.name !== 'tabAudios'" src="assets/ICONOS/audios-gris.svg" />
          <ion-icon v-show="$route.name == 'tabAudios'" src="assets/ICONOS/audios-blanco.svg" />
          <ion-label :class="$route.name == 'tabAudios' ? 'selectedTab' : ''">Audios</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tabSucursales" v-on:click="$router.push('/tabs/tabSucursales'); getddata();"
          id="tabSucursales" class="tabButton">
          <ion-icon v-show="$route.name !== 'tabSucursales'" src="assets/ICONOS/sucursales-gris.svg" />
          <ion-icon v-show="$route.name == 'tabSucursales'" src="assets/ICONOS/sucursales-blanco.svg" />
          <ion-label :class="$route.name == 'tabSucursales' ? 'selectedTab' : ''">ID/Sucursal</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tabLinks" v-on:click="$router.push('/tabs/tabLinks'); getddata();" id="tabLinks"
          class="tabButton">
          <ion-icon v-show="$route.name !== 'tabLinks'" src="assets/ICONOS/links-gris.svg" />
          <ion-icon v-show="$route.name == 'tabLinks'" src="assets/ICONOS/links-blanco.svg" />
          <ion-label :class="$route.name == 'tabLinks' ? 'selectedTab' : ''">Links</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
      <ion-grid class="tab_reproductor">
        <ion-row>
          <ion-col class="col_reproductor">
            <ion-button fill="clear" class="playPauseButton playButton" v-if="!globalPlay" ref="playButton"
              v-on:click="play()" shape="round"><ion-icon src="assets/ICONOS/play.svg"></ion-icon></ion-button>
            <ion-button fill="clear" class="playPauseButton playButton" v-if="!isPlaying && globalPlay" ref="playButton"
              v-on:click="play()" shape="round"><ion-icon
                src="assets/ICONOS/play.svg"></ion-icon></ion-button><!--audios-griss.svg-->
            <ion-button fill="clear" class="playPauseButton pauseButton" v-else-if="globalPlay" ref="pauseButton"
              v-on:click="pause()" shape="round"><ion-icon
                src="assets/ICONOS/pausa.svg"></ion-icon></ion-button><!--audios-blanco.svg-->
          </ion-col>
          <ion-col class="col_title">
            <div>
              <p class="songName"><span>{{ title ? title : 'Cargando...' }}</span></p>
              <p class="playlistName"><span>{{ artist ? artist : 'Vybroo' }}</span></p>
            </div>
          </ion-col>
          <ion-col class="col_progressbar">
            <ion-grid class="gridd">
              <ion-row class="roww">
                <ion-col size="12" class="progressBarCol">
                  <ion-progress-bar :value="songProgress" buffer="1"></ion-progress-bar>
                </ion-col>
                <ion-col size="6" class="songTimeCol">
                  <p class="songTime">{{ currentlyTimer ? currentlyTimer : "00:00" }}</p>
                </ion-col>
                <ion-col size="6" class="songTimeRemainingCol">
                  <p class="songTimeRemaining">{{ totalTimer ? totalTimer : "00:00" }}</p>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>
          <!-- barra de volumen solo en web, no en ios o android -->
          <ion-col class="col_volume" v-show="showVolumeCol">
            <span class="close_volume_backdrop" v-on:click="hideVolumeBar"></span>
            <ion-range class="range_volume" min="0" max="1" step="0.01" :value="volume"
              @ionChange="changeVolume"></ion-range>
          </ion-col>
          <!-- barra flotante de volumen con el ion-range de arriba -->

          <ion-col class="col_buttons">

            <ion-button fill="clear" title="Me gusta" v-on:click="liked == 0 ? likeInteraction('1') : ''"
              class="likeButton"><ion-icon
                :src="liked == 1 ? 'assets/ICONOS/like.svg' : 'assets/ICONOS/like-linea.svg'"></ion-icon></ion-button>
            <ion-button fill="clear" title="No me gusta" v-on:click="liked == 0 ? likeInteraction('2') : ''"
              class="dislikeButton"><ion-icon
                :src="liked == 2 ? 'assets/ICONOS/dislike.svg' : 'assets/ICONOS/dislike-linea.svg'"></ion-icon></ion-button>

            <ion-button fill="clear" class="volumeButton" id="tabVolumen" v-on:click="showVolumeBar()" shape="round" title="Cambiar volumen">
              <ion-icon v-show="showVolumeCol && volume == 0" src="assets/ICONOS/volume_muted_white.svg"></ion-icon>
              <ion-icon v-show="showVolumeCol && volume <= 0.2 && volume > 0" src="assets/ICONOS/volume_low_white.svg"></ion-icon>
              <ion-icon v-show="showVolumeCol && volume > 0.20001 && volume < 0.7" src="assets/ICONOS/volume_medium_white.svg"></ion-icon>
              <ion-icon v-show="showVolumeCol && volume >= 0.7" src="assets/ICONOS/volume_high_white.svg"></ion-icon>
            </ion-button>

            <ion-button fill="clear" class="optionsButton" id="qualityOptions" v-if="quality_controls"
              v-on:click="showQualityOptionsButton()" shape="round" title="Calidad de sonido"><ion-icon
                src="assets/ICONOS/options2_white.svg"></ion-icon></ion-button>

            <ion-button fill="clear" title="Pantalla completa" @click="openFullScreen" class="fulscreenButton"><ion-icon
                :src="'assets/ICONOS/fullscreen.svg'"></ion-icon></ion-button>

          </ion-col>
        </ion-row>
        <p class="sucursal_id" v-if="currentSucursal === currentVarianteName && currentSucursal !== ''">{{ "ID/VAR: " +
          currentSucursal }}</p>
        <p class="sucursal_id" v-else-if="currentSucursal !== currentVarianteName">
          <span v-if="currentSucursal !== ''">{{ "ID: " + currentSucursal }}</span>
          <span v-if="currentSucursal !== '' && currentVarianteName !== ''"> | </span>
          <span v-if="currentVarianteName !== ''">{{ "VAR: " + currentVarianteName }}</span>
        </p>

      </ion-grid>
    </ion-tabs>
  </ion-page>
</template>

<script lang="js">
/* eslint-disable */
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  modalController,
  IonButton,
  IonCol,
  IonRange,
  IonProgressBar,
  IonRow,
  IonGrid,
  IonImg,
  alertController
} from '@ionic/vue';
import Vue from 'vue';
import { images, ellipse, square, triangle } from 'ionicons/icons';
import RepModal from './ModalRepFullView.vue';
import { Storage } from '@capacitor/storage';
import { App } from '@capacitor/app';
import { BackgroundMode } from '@ionic-native/background-mode';
import { Capacitor } from '@capacitor/core';

export default {
  name: 'Tabs',
  components: {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    modalController,
    IonButton,
    IonCol,
    IonRange,
    IonProgressBar,
    IonRow,
    IonGrid,
    IonImg
  },
  data() {
    return {
      audio: null,
      title: '',
      artist: 'Vybroo',
      type: 'spot',
      location: '',
      token: -1,
      active: false,
      muted: false,
      globalPlay: false,
      isPlaying: false,
      currentSucursal: "",
      currentVariante: "",
      currentVarianteName: "",
      currentCategoria: "0",
      currentCategoriaAux: null,
      currentlyTimer: "00:00",
      totalTimer: "00:00",
      songProgress: 0,
      liked: 0,
      notificacionesPermitidas: false,
      volume: 0.5,
      timer_hideVolumeBar: null,
      quality_controls: false,
      showVolumeCol: false,
      permissions: {},
      fullScreenOn: false,
    }
  },
  methods: {
    showVolumeBar() {
      //quitarle el display none a .col_volume
      document.querySelector('.col_volume').classList.add('show');
      // console.log('showVolumeBar');
      //ocultar la barra de volumen despues de 5 segundos
      clearTimeout(this.timer_hideVolumeBar);
      this.timer_hideVolumeBar = setTimeout(() => {
        this.hideVolumeBar();
      }, 5000);
    },
    hideVolumeBar() {
      //quitarle el display none a .col_volume
      document.querySelector('.col_volume').classList.remove('show');
      clearTimeout(this.timer_hideVolumeBar);
      // console.log('hideVolumeBar');
    },
    showQualityOptionsButton() {
      this.emitter.emit("rep-func-quality-options", "Mostrando opciones de calidad");
    },
    changeVolume({ detail }) {
      this.volume = detail.value;
      // console.log('volume Tabs:', this.volume);
      this.emitter.emit("rep-func-volume", this.volume);
      //reiniciar el timer para ocultar la barra de volumen
      clearTimeout(this.timer_hideVolumeBar);
      this.timer_hideVolumeBar = setTimeout(() => {
        this.hideVolumeBar();
      }, 5000);
    },
    async sessVer() {
      const ret = await Storage.get({ key: "clientData" });
      if (ret.value) {
        if (window.location.pathname != '/tabs/tabReproductor') {
          this.$router.push('/tabs/tabReproductor');
        }
      } else {
        Storage.clear();
        // this.presentAlertConfirm("","No se ha encontrado información de la sesión, fué redirigido a la pantalla inicial.");
        this.$router.push('/login/loginUser');
      }
    },
    play() {
      this.emitter.emit("rep-func-play", "Reproduciendo Reproductor");
    },
    pause() {
      this.emitter.emit("rep-func-pause", "Muteando Reproductor");
    },
    likeInteraction(likeValue) {
      this.emitter.emit("rep-func-likeInteraction", likeValue);
    },
    getddata() {
      setTimeout(() => {
        console.log('getddata');
        this.emitter.emit("getddata", 'asd');
      }, 1000);
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
    async openFullScreen() {
      if (this.fullScreenOn) {
        return;
      }
      this.fullScreenOn = true;
      this.emitter.emit("fullScreenOn", true);
      this.emitter.emit("videoOn", true);
      const modal = await modalController.create({
        component: RepModal,
        //backdropDismiss: false,
        cssClass: 'full_modal',
        componentProps: {
          title: 'full_modal',
          video: '',
        },
      });
      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned !== null) {
          this.emitter.emit("videoOn", false); 
          this.fullScreenOn = false;
          console.log("Modal cerrado desde Tabs");
        }
      });
      return modal.present();
    },

    applyPermissions() {
      const tabCategorias = document.getElementById('tabCategorias');
      if (tabCategorias) {
        if (this.permissions.show_categories) {
          tabCategorias.disabled = false;
          tabCategorias.style.display = "block";
        } else {
          tabCategorias.disabled = true;
          tabCategorias.style.display = "none";
        }
      }

      const tabSucursales = document.getElementById('tabSucursales');
      if (tabSucursales) {
        if (this.permissions.show_branches) {
          tabSucursales.disabled = false;
          tabSucursales.style.display = "block";
        } else {
          tabSucursales.disabled = true;
          tabSucursales.style.display = "none";
        }
      }

      const tabAudios = document.getElementById('tabAudios');
      if (tabAudios) {
        if (this.permissions.show_ondemand) {
          tabAudios.disabled = false;
          tabAudios.style.display = "block";
        } else {
          tabAudios.disabled = true;
          tabAudios.style.display = "none";
        }
      }

      const tabVariantes = document.getElementById('tabVariantes');
      if (tabVariantes) {
        if (this.permissions.show_branches) {
          tabVariantes.disabled = false;
          tabVariantes.style.display = "block";
        } else {
          tabVariantes.disabled = true;
          tabVariantes.style.display = "none";
        }
      }

      const tabLinks = document.getElementById('tabLinks');
      if (tabLinks) {
        if (this.permissions.show_links) {
          tabLinks.disabled = false;
          tabLinks.style.display = "block";
        } else {
          tabLinks.disabled = true;
          tabLinks.style.display = "none";
        }
      }

      const tabVolumen = document.getElementById('tabVolumen');
      if (tabVolumen) {
        if (this.permissions.show_volume) {
          tabVolumen.disabled = false;
          tabVolumen.style.display = "inline-block";
        } else {
          tabVolumen.disabled = true;
          tabVolumen.style.display = "none";
        }
      }

      if (!this.permissions.show_categories && !this.permissions.show_branches && !this.permissions.show_ondemand && !this.permissions.show_links) {
        document.querySelector('.tabBarBottom').style.display = "none";
      } else {
        // document.querySelector('.tabBarBottom').style.display = "inherit";
      }
    }



  },
  setup() {
    return {
      images,
      ellipse,
      square,
      triangle,
    }
  },
  mounted() {
    if (Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android') {
      this.showVolumeCol = false;
      // console.log("Desactivando barra de volumen en ios o android, showVolumeCol: ", this.showVolumeCol)
      // console.log("Plataforma showVolumeCol: ", Capacitor.getPlatform())
    } else {
      this.showVolumeCol = true;
      // console.log("Activando barra de volumen en web, showVolumeCol: ", this.showVolumeCol)
      // console.log("Plataforma showVolumeCol: ", Capacitor.getPlatform())

    }
    this.emitter.on("rep-title", title => { this.title = title; });
    //this.emitter.emit("rep-title", this.title);
    this.emitter.on("rep-artist", artist => { this.artist = artist; });
    this.emitter.on("rep-type", type => { this.type = type; });
    this.emitter.on("rep-token", token => { this.token = token; });
    this.emitter.on("rep-isPlaying", isPlaying => { this.isPlaying = isPlaying; });
    this.emitter.on("rep-currentlyTimer", currentlyTimer => { this.currentlyTimer = currentlyTimer; });
    this.emitter.on("rep-totalTimer", totalTimer => { this.totalTimer = totalTimer; });
    this.emitter.on("rep-songProgress", songProgress => { this.songProgress = songProgress; });
    this.emitter.on("rep-liked", liked => { this.liked = liked; });
    this.emitter.on("rep-muted", muted => { this.muted = muted; });
    this.emitter.on("rep-globalPlay", globalPlay => { this.globalPlay = globalPlay; });
    this.emitter.on("rep-currentSucursal", currentSucursal => { this.currentSucursal = currentSucursal; });
    this.emitter.on("rep-currentVariante", currentVariante => { this.currentVariante = currentVariante; });
    this.emitter.on("rep-currentVarianteName", currentVarianteName => { this.currentVarianteName = currentVarianteName; });
    this.emitter.on("rep-currentCategoria", currentCategoria => { this.currentCategoria = currentCategoria; });
    this.emitter.on("rep-currentCategoriaAux", currentCategoriaAux => { this.currentCategoriaAux = currentCategoriaAux; });

    this.emitter.on("permissions", permissions => { this.permissions = permissions; this.applyPermissions(); });

    this.emitter.on("quality-controls", quality_controls => { this.quality_controls = quality_controls; });
    this.sessVer();
    // this.showNotification("Hola", "Esto es una notificación", "025");
  },
  created() {
    BackgroundMode.enable();
  }
}
</script>

<style scoped>
.selectedTab {
  font-weight: bold;
  color: white;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.col_volume {
  max-width: 200px;
  margin: 0;
  padding: 0 25px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.75);
  /* blur al background */
  backdrop-filter: blur(10px);
  /* que flote arriba de la columna col_buttons */
  z-index: 1;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  /* position the column above the col_buttons column */
  bottom: 81px;
  right: 80px;
  position: fixed;
  animation: showVolumeBar 0.1s ease-in-out;
  animation-fill-mode: forwards;
  display: none;
}

.col_volume.show {
  display: flex;
}


.close_volume_backdrop {
  width: 200vw;
  height: 200vh;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100vw) translateY(-100vh);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;


}

.showVolumeBar {
  display: flex;
  /* efecto de transicion al aparecer */
  animation: showVolumeBar 0.1s ease-in-out;

}

@keyframes showVolumeBar {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hideVolumeBar {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

/* modificar el color de la barra y el knob del range_volume (ion-range)*/
.range_volume,
.range-bar {
  filter: brightness(2) !important;
}

.range_volume .bar .range-knob-handle {
  background: #fff !important;
}

.col_options {
  max-width: 50px;
  margin: 0;
  width: 100%;
  display: table;

}

.optionsButton {}

.item_volume {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background: transparent !important;
}

.item_volume .item-native {
  padding: 0 !important;
  background: transparent !important;
}

.item_volume .item-inner {
  padding: 0 !important;
}

.range_volume {
  min-height: 100%;
  padding: 0;
  color: black;
}

.range_volume ion-icon {
  font-size: 20px;
  color: white;
  margin: 0;
}

.songName {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 10px;
  line-height: 25px;
  color: white;
}

.songName .ios {
  color: white;
}

.playlistName {
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 5px;
  line-height: 20px;
  color: white;
}

.playlistName .ios {
  color: white;
}

.playPauseButton {
  font-size: 25px;
  border-radius: 100%;
  height: 80px;
  margin: 0px;
}

.tab_reproductor {
  width: 100%;
  height: auto;
  max-height: 80px;
  margin: 0px;
  padding: 0px;
}

.songName,
.playlistName {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  color: white;
}

.songName:hover>*,
.playlistName:hover>* {
  display: inline-block;
  position: relative;
  animation: 5s linear 0s infinite move;
  min-width: 100%;
  text-overflow: initial;
  max-width: auto;
}


@keyframes move {
  0% {
    transform: translateX(0%);
    left: 0%;
  }

  20% {
    transform: translateX(0%);
    left: 0%;
  }

  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.col_reproductor {
  width: 80px;
  max-width: 80px;
  margin: 0px;
  min-width: 0px;
  padding: 0px;
}

.col_title {
  width: 300px;
  max-width: 300px;
  min-width: 0px;
}

.col_progressbar {
  width: calc(100% - 560px);
  max-width: calc(100% - 560px);
  min-width: 0px;
}

.col_buttons {
  width: 350px;
  max-width: 350px;
  min-width: 300px;
  padding: 0px;
  justify-content: center;
  text-align: center;
}

.progressBarCol {
  margin-top: 12px;
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 18px;
}

.songTimeCol {
  text-align: left;
  color: white;
}

.songTimeRemainingCol {
  text-align: right;
  color: white;
}

.songTime,
.songTimeRemaining {
  font-size: 12px;
  margin-top: 0;
}

.likeButton,
.dislikeButton,
.fulscreenButton,
.optionsButton,
.volumeButton {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  text-align: center;
  height: 50px;
  width: 50px;
  font-size: 16px !important;
  margin: 10px 5px;
}




.songName,
.playlistName {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}

.songName:hover>*,
.playlistName:hover>* {
  display: inline-block;
  position: relative;
  animation: 5s linear 0s infinite move;
  min-width: 100%;
  text-overflow: initial;
  max-width: auto;
}

@media only screen and (min-width: 1220px) {
  .songName {
    font-size: 20px;
  }

  .playlistName {
    font-size: 18px;
  }

  .col_title {
    width: 400px;
    max-width: 400px;
  }

  .col_progressbar {
    width: calc(100% - 660px);
    max-width: calc(100% - 660px);
  }
}

@media only screen and (max-width: 959) {
  .songName {
    font-weight: bold;
    font-size: 15px;
  }

  .playlistName {
    font-size: 13px;
  }

  .col_title {
    width: 250px;
    max-width: 250px;
  }

  .col_progressbar {
    width: calc(100% - 510px);
    max-width: calc(100% - 510px);
  }
}
</style>